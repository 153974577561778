import React from 'react'

const SJCLogo = props => {
  return (
    <svg width="250" height="110" viewBox="0 0 596 221" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M368.687 54.1227H356.155C356.155 54.1227 355.904 53.7894 355.904 52.8707C355.904 51.9507 356.155 51.616 356.155 51.616H368.687V39.084C368.687 39.084 369.021 38.8333 369.94 38.8333C370.861 38.8333 371.195 39.084 371.195 39.084V51.616H383.725C383.725 51.616 383.977 51.9507 383.977 52.8707C383.977 53.7894 383.725 54.1227 383.725 54.1227H371.195V66.656C371.195 66.656 370.861 66.908 369.94 66.908C369.021 66.908 368.687 66.656 368.687 66.656V54.1227Z" fill="#EF3D1F"/>
    <path d="M167.637 49.6187C162.552 47.8067 157.468 46.8134 157.468 43.1307C157.468 39.332 160.156 37.4614 165.825 37.4614C170.735 37.4614 174.533 38.7467 174.533 38.7467C175.877 37.2867 176.52 34.9494 176.52 32.9027C176.52 32.9027 172.021 31.1494 164.773 31.1494C154.837 31.1494 150.045 36.1174 150.045 43.4814C150.045 52.5987 158.403 54.0027 164.423 56.164C167.871 57.392 170.501 58.8534 170.501 62.0094C170.501 65.7507 168.221 67.6787 162.787 67.6787C157.408 67.6787 151.799 65.8667 151.799 65.8667C150.688 67.5614 149.987 69.8414 150.045 71.828C150.045 71.828 155.071 74.4 163.077 74.4C173.072 74.4 178.157 69.9587 178.157 61.8334C178.157 54.352 172.897 51.5467 167.637 49.6187" fill="#1D375E"/>
    <path d="M193.093 57.9173L197.184 46.0533C198.353 42.5467 198.937 39.5667 198.937 39.5667H199.172C199.172 39.5667 199.757 42.4293 200.984 46.0533L204.959 57.9173H193.093ZM199.348 31.7333C196.717 31.7333 194.788 32.26 194.788 32.26L180.82 73.2893C180.82 73.2893 182.047 73.8173 184.268 73.8173C186.605 73.8173 187.892 73.2893 187.892 73.2893L191.048 63.9387H207.004L210.16 73.2893C210.16 73.2893 211.271 73.8173 213.784 73.8173C216.531 73.8173 217.875 73.2893 217.875 73.2893L203.907 32.26C203.907 32.26 202.212 31.7333 199.348 31.7333" fill="#1D375E"/>
    <path d="M223.027 31.7333V73.2893C223.027 73.2893 224.255 73.8173 226.709 73.8173C229.223 73.8173 230.508 73.2893 230.508 73.2893V56.3987H244.829C245.237 55.4627 245.415 54.352 245.415 53.2427C245.415 52.192 245.237 51.1973 244.829 50.3787H230.508V37.7533H246.581C246.991 36.76 247.168 35.7667 247.168 34.7147C247.168 33.6627 246.932 32.6693 246.581 31.7333H223.027Z" fill="#1D375E"/>
    <path d="M260.315 67.7947V55.58H274.051C274.46 54.5867 274.635 53.5347 274.635 52.4827C274.635 51.488 274.401 50.496 274.051 49.56H260.315V37.7533H276.973C277.383 36.76 277.556 35.6493 277.556 34.5387C277.556 33.5453 277.324 32.6107 276.973 31.7333H252.832V73.8173H277.556C277.967 72.8227 278.14 71.7693 278.14 70.7187C278.14 69.7253 277.908 68.7307 277.556 67.7947H260.315" fill="#1D375E"/>
    <path d="M282.575 31.7333C282.575 31.7333 281.991 33.136 281.991 35.0067C281.991 36.936 282.575 38.1627 282.575 38.1627H293.211V73.2893C293.211 73.2893 294.263 73.8173 296.953 73.8173C299.349 73.8173 300.693 73.2893 300.693 73.2893V38.1627H311.037C311.037 38.1627 311.623 36.76 311.623 34.8893C311.623 33.136 311.037 31.7333 311.037 31.7333H282.575" fill="#1D375E"/>
    <path d="M346.852 31.7333C344.339 31.7333 342.995 32.26 342.995 32.26L333.352 48.8013L323.824 32.26C323.824 32.26 322.305 31.7333 319.616 31.7333C316.636 31.7333 315.057 32.26 315.057 32.26L329.084 56.4573V73.2893C329.084 73.2893 330.252 73.8173 332.941 73.8173C335.339 73.8173 336.565 73.2893 336.565 73.2893V56.2813L351.001 32.26C351.001 32.26 349.248 31.7333 346.852 31.7333" fill="#1D375E"/>
    <path d="M402.229 32.26V62.0667C402.229 66.3934 399.305 67.9134 395.743 67.9134C393.053 67.9134 390.657 67.036 390.657 67.036C389.253 68.496 388.611 70.4254 388.669 72.6467C388.669 72.6467 392.7 74.4 397.496 74.4C404.451 74.4 409.711 70.3094 409.711 61.8334V32.26C409.711 32.26 408.484 31.7334 405.969 31.7334C403.281 31.7334 402.229 32.26 402.229 32.26Z" fill="#1D375E"/>
    <path d="M440.915 32.26V60.2574C440.915 65.1654 438.167 68.088 433.14 68.088C428.113 68.088 425.309 65.1654 425.309 60.2574V32.26C425.309 32.26 424.024 31.7347 421.509 31.7347C419.053 31.7347 417.827 32.26 417.827 32.26V59.088C417.827 68.7307 423.789 74.3987 433.025 74.3987C442.257 74.3987 448.277 68.9067 448.277 59.088V32.26C448.277 32.26 446.992 31.7347 444.536 31.7347C442.084 31.7347 440.915 32.26 440.915 32.26Z" fill="#1D375E"/>
    <path d="M471.987 49.6187C466.901 47.8067 461.816 46.8134 461.816 43.1307C461.816 39.332 464.505 37.4614 470.175 37.4614C475.085 37.4614 478.883 38.7467 478.883 38.7467C480.227 37.2867 480.869 34.9494 480.869 32.9027C480.869 32.9027 476.371 31.1494 469.123 31.1494C459.188 31.1494 454.395 36.1174 454.395 43.4814C454.395 52.5987 462.753 54.0027 468.771 56.164C472.22 57.392 474.852 58.8534 474.852 62.0094C474.852 65.7507 472.572 67.6787 467.137 67.6787C461.757 67.6787 456.148 65.8667 456.148 65.8667C455.037 67.5614 454.335 69.8414 454.395 71.828C454.395 71.828 459.42 74.4 467.427 74.4C477.421 74.4 482.507 69.9587 482.507 61.8334C482.507 54.352 477.248 51.5467 471.987 49.6187" fill="#1D375E"/>
    <path d="M486.439 31.7333C486.439 31.7333 485.855 33.136 485.855 35.0067C485.855 36.936 486.439 38.1627 486.439 38.1627H497.076V73.2893C497.076 73.2893 498.128 73.8173 500.816 73.8173C503.213 73.8173 504.557 73.2893 504.557 73.2893V38.1627H514.903C514.903 38.1627 515.487 36.76 515.487 34.8893C515.487 33.136 514.903 31.7333 514.903 31.7333H486.439" fill="#1D375E"/>
    <path d="M520.773 32.26V73.2893C520.773 73.2893 521.825 73.8173 524.515 73.8173C526.912 73.8173 528.255 73.2893 528.255 73.2893V32.26C528.255 32.26 526.969 31.7333 524.455 31.7333C522 31.7333 520.773 32.26 520.773 32.26Z" fill="#1D375E"/>
    <path d="M553.648 67.9134C547.455 67.9134 542.955 64.9307 542.955 52.832C542.955 40.7347 547.455 37.52 553.648 37.52C557.391 37.52 560.721 38.7467 560.721 38.7467C562.067 37.2867 563.119 35.592 562.943 33.1947C562.943 33.1947 559.317 31.1494 552.832 31.1494C541.257 31.1494 535.121 38.6894 535.121 52.7747C535.121 66.8614 541.257 74.4 552.832 74.4C559.612 74.4 563.527 72.3547 563.527 72.3547C563.527 70.1907 562.768 68.0294 561.304 66.6854C561.304 66.6854 557.859 67.9134 553.648 67.9134" fill="#1D375E"/>
    <path d="M593.699 67.7947H576.457V55.58H590.193C590.603 54.5867 590.777 53.5347 590.777 52.4827C590.777 51.488 590.544 50.496 590.193 49.56H576.457V37.7533H593.116C593.525 36.76 593.699 35.6493 593.699 34.5387C593.699 33.5453 593.465 32.6107 593.116 31.7333H568.976V73.8173H593.699C594.109 72.8227 594.284 71.7693 594.284 70.7187C594.284 69.7253 594.051 68.7307 593.699 67.7947" fill="#1D375E"/>
    <path d="M162.148 120.067C155.283 120.067 150.775 116.43 150.775 103.571C150.775 90.7127 155.335 86.9211 162.148 86.9211C166.503 86.9211 169.473 88.3049 169.473 88.3049C169.987 87.7397 170.293 87.0747 170.345 86.256C170.345 86.256 167.373 84.564 161.789 84.564C152.516 84.564 147.957 90.9689 147.957 103.518C147.957 116.275 152.568 122.476 161.789 122.476C167.835 122.476 170.909 120.784 170.909 120.784C170.857 120.015 170.549 119.197 170.037 118.683C170.037 118.683 166.912 120.067 162.148 120.067" fill="#1D375E"/>
    <path d="M198.96 85.2813V101.83H180.107V85.2813C180.107 85.2813 179.644 85.076 178.775 85.076C177.904 85.076 177.444 85.2813 177.444 85.2813V121.758C177.444 121.758 177.801 121.963 178.775 121.963C179.644 121.963 180.107 121.758 180.107 121.758V104.288H198.96V121.758C198.96 121.758 199.268 121.963 200.291 121.963C201.215 121.963 201.623 121.758 201.623 121.758V85.2813C201.623 85.2813 201.163 85.076 200.291 85.076C199.42 85.076 198.96 85.2813 198.96 85.2813Z" fill="#1D375E"/>
    <path d="M215.252 108.95L222.22 91.1736C222.784 89.5851 223.04 88.508 223.04 88.508H223.144C223.144 88.508 223.348 89.5331 223.963 91.1736L230.828 108.95H215.252ZM223.144 85.076C222.273 85.076 221.811 85.2813 221.811 85.2813L207.723 121.758C207.723 121.758 208.081 121.963 208.952 121.963C209.925 121.963 210.335 121.758 210.335 121.758L214.435 111.306H231.7L235.747 121.758C235.747 121.758 236.259 121.963 237.131 121.963C238.103 121.963 238.564 121.758 238.564 121.758L224.476 85.2813C224.476 85.2813 224.015 85.076 223.144 85.076" fill="#1D375E"/>
    <path d="M247.325 119.555V85.2813C247.325 85.2813 246.916 85.076 245.995 85.076C245.123 85.076 244.663 85.2813 244.663 85.2813V121.963H263.873C263.925 121.758 264.079 121.348 264.079 120.734C264.079 120.169 263.925 119.707 263.873 119.555H247.325" fill="#1D375E"/>
    <path d="M272.959 119.555V85.2813C272.959 85.2813 272.549 85.076 271.628 85.076C270.756 85.076 270.296 85.2813 270.296 85.2813V121.963H289.507C289.559 121.758 289.712 121.348 289.712 120.734C289.712 120.169 289.559 119.707 289.507 119.555H272.959" fill="#1D375E"/>
    <path d="M298.591 119.607V104.391H313.399C313.552 104.031 313.603 103.674 313.603 103.211C313.603 102.802 313.5 102.445 313.399 102.085H298.591V87.4325H315.96C316.113 87.0747 316.165 86.716 316.165 86.204C316.165 85.7427 316.063 85.384 315.96 85.076H295.929V121.963H316.472C316.625 121.603 316.677 121.195 316.677 120.734C316.677 120.323 316.575 119.965 316.472 119.607H298.591Z" fill="#1D375E"/>
    <path d="M346.044 85.28V111.614C346.044 115.609 346.251 118.427 346.251 118.427H346.148C346.148 118.427 345.072 115.917 343.432 113.201L326.577 85.28C326.577 85.28 326.116 85.076 325.04 85.076C324.016 85.076 323.453 85.28 323.453 85.28V121.758C323.453 121.758 323.86 121.963 324.681 121.963C325.604 121.963 326.013 121.758 326.013 121.758V95.7336C326.013 90.8143 325.809 88.5616 325.809 88.5616H325.911C325.911 88.5616 326.68 90.6096 328.473 93.4783L345.431 121.758C345.431 121.758 345.943 121.963 347.019 121.963C348.095 121.963 348.556 121.758 348.556 121.758V85.28C348.556 85.28 348.196 85.076 347.325 85.076C346.404 85.076 346.044 85.28 346.044 85.28" fill="#1D375E"/>
    <path d="M368.809 103.622C368.809 104.339 369.013 104.801 369.013 104.801H378.236V119.248C377.007 119.504 374.393 120.067 371.269 120.067C364.404 120.067 359.281 116.43 359.281 103.571C359.281 90.7127 364.455 86.9211 371.269 86.9211C375.777 86.9211 379.415 88.2528 379.415 88.2528C379.875 87.7397 380.183 86.9716 380.285 86.256C380.285 86.256 376.801 84.564 370.909 84.564C361.637 84.564 356.463 90.9689 356.463 103.518C356.463 116.275 361.688 122.476 370.909 122.476C377.057 122.476 380.849 120.784 380.849 120.784V102.443H369.013C369.013 102.443 368.809 102.802 368.809 103.622" fill="#1D375E"/>
    <path d="M409.652 119.607H391.772V104.391H406.58C406.732 104.031 406.783 103.674 406.783 103.211C406.783 102.802 406.68 102.445 406.58 102.085H391.772V87.4325H409.14C409.293 87.0747 409.344 86.716 409.344 86.204C409.344 85.7427 409.244 85.384 409.14 85.076H389.109V121.963H409.652C409.805 121.603 409.859 121.195 409.859 120.734C409.859 120.323 409.755 119.965 409.652 119.607" fill="#1D375E"/>
    <path d="M103.061 18.16H18.164V67.4667H72.6604V72.66L37.0839 72.6627C36.8 73.1573 36.3432 75.356 36.3432 79.1493C36.3432 82.928 36.7964 85.124 37.0803 85.632H85.6355V54.492H31.1375V31.1347H103.061C103.345 30.636 103.801 28.4387 103.801 24.648C103.801 20.856 103.345 18.6587 103.061 18.16" fill="#EF3D1F"/>
    <path d="M115.48 31.1347C119.271 31.1347 121.471 30.6787 121.968 30.3947V-0.00530686H0V121.964H121.968V91.5727C121.471 91.2903 119.271 90.8336 115.48 90.8336C111.689 90.8336 109.49 91.2893 108.993 91.5727V108.992H12.9708V12.968H108.993V30.3947C109.49 30.6774 111.689 31.1347 115.48 31.1347Z" fill="#EF3D1F"/>
    <path d="M24.6516 72.664C20.8604 72.664 18.6604 73.12 18.1646 73.404V103.798H103.801V36.3254H37.0834C36.7984 36.8227 36.3438 39.02 36.3438 42.8107C36.3438 46.6014 36.7984 48.8014 37.0834 49.2987H90.8276V90.8252H31.1364V73.404C30.6402 73.12 28.4412 72.664 24.6516 72.664" fill="#EF3D1F"/>
    <path d="M10.0469 152.434C13.9418 152.434 16.4464 153.348 16.4464 153.348C16.4464 154.059 16.2094 154.803 15.7698 155.311C15.7698 155.311 13.3318 154.6 10.386 154.6C6.42347 154.6 4.52763 156.26 4.52763 159.037C4.52763 162.117 7.40628 162.795 10.7578 163.776C14.0422 164.792 17.361 166.08 17.361 170.651C17.361 174.984 14.4151 177.491 9.09847 177.491C4.79847 177.491 2.02295 176.136 2.02295 176.136C2.02295 175.459 2.2594 174.68 2.66514 174.137C2.66514 174.137 5.74691 175.188 8.92972 175.188C12.7558 175.188 14.787 173.63 14.787 170.684C14.787 167.739 12.4511 166.859 9.77607 166.08C6.18648 164.961 2.02295 164.081 2.02295 159.171C2.02295 155.108 4.7641 152.434 10.0469 152.434" fill="#1D375E"/>
    <path d="M21.0448 159.511C21.0448 159.511 21.4844 159.341 22.2631 159.341C23.1093 159.341 23.4812 159.511 23.4812 159.511V171.936C23.4812 174.272 24.7005 175.459 27.1729 175.459C28.9667 175.459 30.8287 174.341 31.8797 172.376V159.511C31.8797 159.511 32.2855 159.341 33.0979 159.341C33.9448 159.341 34.3161 159.511 34.3161 159.511V176.981C34.3161 176.981 33.9448 177.152 33.0979 177.152C32.2855 177.152 31.8797 176.981 31.8797 176.981V174.848C30.5579 176.508 28.5953 177.491 26.2579 177.491C22.9396 177.491 21.0448 175.459 21.0448 172.411V159.511" fill="#1D375E"/>
    <path d="M45.8984 161.068C43.0536 161.068 41.5307 163.505 41.4964 166.012V170.38C41.4964 172.885 43.0197 175.424 45.8984 175.424C49.2156 175.424 50.6724 173.596 50.6724 168.247C50.6724 162.896 49.2156 161.068 45.8984 161.068V161.068ZM46.7115 159.003C50.6724 159.003 53.2125 161.339 53.2125 168.247C53.2125 175.153 50.6724 177.491 46.7115 177.491C44.1031 177.491 42.4781 176.508 41.4964 174.848V185.075C41.4964 185.075 41.1235 185.244 40.2781 185.244C39.4312 185.244 39.0584 185.075 39.0584 185.075V159.511C39.0584 159.511 39.4312 159.341 40.2781 159.341C41.1235 159.341 41.4964 159.511 41.4964 159.511V161.644C42.4781 159.984 44.1031 159.003 46.7115 159.003" fill="#1D375E"/>
    <path d="M64.1849 161.068C61.3401 161.068 59.8167 163.505 59.7828 166.012V170.38C59.7828 172.885 61.3063 175.424 64.1849 175.424C67.5021 175.424 68.9588 173.596 68.9588 168.247C68.9588 162.896 67.5021 161.068 64.1849 161.068V161.068ZM64.9973 159.003C68.9588 159.003 71.4984 161.339 71.4984 168.247C71.4984 175.153 68.9588 177.491 64.9973 177.491C62.3896 177.491 60.764 176.508 59.7828 174.848V185.075C59.7828 185.075 59.4099 185.244 58.5645 185.244C57.7177 185.244 57.3448 185.075 57.3448 185.075V159.511C57.3448 159.511 57.7177 159.341 58.5645 159.341C59.4099 159.341 59.7828 159.511 59.7828 159.511V161.644C60.764 159.984 62.3896 159.003 64.9973 159.003" fill="#1D375E"/>
    <path d="M82.1833 160.934C79.2713 160.934 77.4432 162.592 77.4432 168.247C77.4432 173.901 79.2713 175.56 82.1833 175.56C85.0948 175.56 86.9245 173.901 86.9245 168.247C86.9245 162.592 85.0948 160.934 82.1833 160.934ZM82.1833 159.003C86.7203 159.003 89.4292 161.541 89.4292 168.247C89.4292 174.951 86.7203 177.491 82.1833 177.491C77.6464 177.491 74.9375 174.951 74.9375 168.247C74.9375 161.541 77.6464 159.003 82.1833 159.003Z" fill="#1D375E"/>
    <path d="M100.511 159.037C101.968 159.037 102.949 159.544 103.423 159.849C103.322 160.728 102.983 161.372 102.306 161.915C101.561 161.44 100.918 161.237 100.004 161.237C98.4464 161.237 96.9563 162.592 95.9407 164.623V176.981C95.9407 176.981 95.5677 177.152 94.7208 177.152C93.8755 177.152 93.5027 176.981 93.5027 176.981V159.511C93.5027 159.511 93.8755 159.341 94.7208 159.341C95.5677 159.341 95.9407 159.511 95.9407 159.511V162.287C97.3631 159.679 99.1229 159.037 100.511 159.037Z" fill="#1D375E"/>
    <path d="M110.887 170.989C110.887 174.24 111.802 175.459 114.477 175.459C115.323 175.459 116.136 175.289 116.136 175.289C116.136 175.289 116.339 175.695 116.339 176.305C116.339 176.88 116.203 177.22 116.203 177.22C115.865 177.32 114.951 177.491 113.833 177.491C109.837 177.491 108.449 175.222 108.449 171.091V161.271H105.808C105.808 161.271 105.64 160.934 105.64 160.323C105.64 159.645 105.808 159.341 105.808 159.341H108.449V156.124C109.093 155.684 110.007 155.515 110.887 155.581V159.341H115.729C115.729 159.341 115.899 159.679 115.899 160.289C115.899 160.899 115.729 161.271 115.729 161.271H110.887V170.989Z" fill="#1D375E"/>
    <path d="M125.512 161C122.803 161 120.975 162.795 120.772 167.197H129.372C129.406 166.959 129.475 166.316 129.475 165.267C129.475 162.964 128.424 161 125.512 161V161ZM120.772 169.025C121.009 173.664 122.735 175.324 126.02 175.324C128.594 175.324 130.151 174.205 130.151 174.205C130.625 174.577 130.998 175.289 131.032 176C131.032 176 129.17 177.491 125.546 177.491C120.67 177.491 118.232 174.272 118.232 168.144C118.232 161.745 121.178 159.003 125.512 159.003C129.609 159.003 131.879 161.44 131.879 165.537C131.879 167.636 131.505 169.025 131.505 169.025H120.772Z" fill="#1D375E"/>
    <path d="M142.469 161.068C139.184 161.068 137.695 162.896 137.695 168.247C137.695 173.596 139.184 175.424 142.469 175.424C145.347 175.424 146.871 172.885 146.871 170.38V166.045C146.837 163.573 145.312 161.068 142.469 161.068ZM148.089 151.757C148.936 151.757 149.309 151.925 149.309 151.925V176.981C149.309 176.981 148.901 177.152 148.292 177.152C147.649 177.152 147.208 176.981 147.208 176.981L146.972 174.713C145.991 176.406 144.331 177.491 141.656 177.491C137.695 177.491 135.155 175.153 135.155 168.247C135.155 161.339 137.695 159.003 141.656 159.003C144.229 159.003 145.855 159.984 146.871 161.644V151.925C146.871 151.925 147.243 151.757 148.089 151.757Z" fill="#1D375E"/>
    <path d="M168.917 161.068C166.072 161.068 164.548 163.505 164.515 166.012V170.38C164.515 172.885 166.037 175.424 168.917 175.424C172.235 175.424 173.691 173.596 173.691 168.247C173.691 162.896 172.235 161.068 168.917 161.068V161.068ZM164.515 161.644C165.496 159.984 167.121 159.003 169.729 159.003C173.691 159.003 176.231 161.339 176.231 168.247C176.231 175.153 173.691 177.491 169.729 177.491C167.121 177.491 165.496 176.508 164.515 174.848V176.981C164.515 176.981 164.141 177.152 163.296 177.152C162.449 177.152 162.077 176.981 162.077 176.981V151.925C162.077 151.925 162.449 151.757 163.296 151.757C164.141 151.757 164.515 151.925 164.515 151.925V161.644Z" fill="#1D375E"/>
    <path d="M186.927 180.436C185.844 183.687 184.725 185.617 181.68 185.617C180.664 185.617 179.952 185.345 179.952 185.345C179.919 185.277 179.749 184.871 179.749 184.397C179.749 183.889 179.919 183.449 179.987 183.381C179.987 183.381 180.631 183.551 181.443 183.551C182.897 183.551 183.711 182.908 184.693 179.961L185.708 177.117C185.437 177.117 185.167 177.049 184.896 176.948L178.124 159.511C178.124 159.511 178.564 159.341 179.411 159.341C180.257 159.341 180.697 159.511 180.697 159.511L185.268 171.124C186.081 173.223 186.487 174.748 186.487 174.748H186.589C186.589 174.748 186.961 173.257 187.673 171.124L191.668 159.511C191.668 159.511 192.041 159.341 192.887 159.341C193.667 159.341 194.105 159.511 194.105 159.511L186.927 180.436" fill="#1D375E"/>
    <path d="M208.533 170.989C208.533 174.24 209.448 175.459 212.123 175.459C212.969 175.459 213.781 175.289 213.781 175.289C213.781 175.289 213.985 175.695 213.985 176.305C213.985 176.88 213.849 177.22 213.849 177.22C213.511 177.32 212.596 177.491 211.479 177.491C207.484 177.491 206.095 175.222 206.095 171.091V161.271H203.455C203.455 161.271 203.284 160.934 203.284 160.323C203.284 159.645 203.455 159.341 203.455 159.341H206.095V156.124C206.739 155.684 207.652 155.515 208.533 155.581V159.341H213.375C213.375 159.341 213.545 159.679 213.545 160.289C213.545 160.899 213.375 161.271 213.375 161.271H208.533V170.989Z" fill="#1D375E"/>
    <path d="M230.432 176.981C230.432 176.981 229.993 177.152 229.213 177.152C228.368 177.152 227.995 176.981 227.995 176.981V164.555C227.995 162.218 226.776 161.034 224.337 161.034C222.475 161.034 220.613 162.185 219.597 164.115V176.981C219.597 176.981 219.192 177.152 218.377 177.152C217.532 177.152 217.16 176.981 217.16 176.981V151.925C217.16 151.925 217.532 151.757 218.377 151.757C219.192 151.757 219.597 151.925 219.597 151.925V161.644C220.917 159.984 222.848 159.003 225.217 159.003C228.536 159.003 230.432 161.034 230.432 164.081V176.981" fill="#1D375E"/>
    <path d="M241.621 161C238.912 161 237.084 162.795 236.881 167.197H245.481C245.516 166.959 245.584 166.316 245.584 165.267C245.584 162.964 244.532 161 241.621 161ZM236.881 169.025C237.119 173.664 238.844 175.324 242.128 175.324C244.703 175.324 246.26 174.205 246.26 174.205C246.733 174.577 247.107 175.289 247.14 176C247.14 176 245.279 177.491 241.655 177.491C236.779 177.491 234.341 174.272 234.341 168.144C234.341 161.745 237.287 159.003 241.621 159.003C245.719 159.003 247.987 161.44 247.987 165.537C247.987 167.636 247.615 169.025 247.615 169.025H236.881" fill="#1D375E"/>
    <path d="M266.173 152.975C266.173 152.975 266.512 152.772 267.427 152.772C268.273 152.772 268.679 152.975 268.679 152.975V171.192C268.679 175.492 266.512 177.491 262.72 177.491C260.079 177.491 257.945 176.473 257.945 176.473C257.912 175.695 258.148 175.018 258.623 174.544C258.623 174.544 260.349 175.255 262.28 175.255C264.549 175.255 266.173 174.272 266.173 171.26V152.975" fill="#1D375E"/>
    <path d="M280.332 160.934C277.42 160.934 275.592 162.592 275.592 168.247C275.592 173.901 277.42 175.56 280.332 175.56C283.243 175.56 285.073 173.901 285.073 168.247C285.073 162.592 283.243 160.934 280.332 160.934ZM280.332 159.003C284.869 159.003 287.577 161.541 287.577 168.247C287.577 174.951 284.869 177.491 280.332 177.491C275.795 177.491 273.085 174.951 273.085 168.247C273.085 161.541 275.795 159.003 280.332 159.003Z" fill="#1D375E"/>
    <path d="M304.923 176.981C304.923 176.981 304.483 177.152 303.703 177.152C302.857 177.152 302.485 176.981 302.485 176.981V164.555C302.485 162.218 301.265 161.034 298.827 161.034C296.965 161.034 295.103 162.185 294.088 164.115V176.981C294.088 176.981 293.683 177.152 292.868 177.152C292.023 177.152 291.649 176.981 291.649 176.981V151.925C291.649 151.925 292.023 151.757 292.868 151.757C293.683 151.757 294.088 151.925 294.088 151.925V161.644C295.408 159.984 297.339 159.003 299.708 159.003C303.027 159.003 304.923 161.034 304.923 164.081V176.981Z" fill="#1D375E"/>
    <path d="M322.876 176.981C322.876 176.981 322.436 177.152 321.656 177.152C320.811 177.152 320.44 176.981 320.44 176.981V164.555C320.44 162.218 319.22 161.034 316.78 161.034C314.92 161.034 313.056 162.185 312.041 164.115V176.981C312.041 176.981 311.635 177.152 310.821 177.152C309.976 177.152 309.604 176.981 309.604 176.981V159.511C309.604 159.511 309.976 159.341 310.821 159.341C311.635 159.341 312.041 159.511 312.041 159.511V161.644C313.363 159.984 315.292 159.003 317.663 159.003C320.981 159.003 322.876 161.034 322.876 164.081V176.981Z" fill="#1D375E"/>
    <path d="M342.96 175.018C347.597 175.018 350.341 173.088 350.341 164.961C350.341 156.801 347.597 154.905 342.96 154.905H338.592V175.018H342.96ZM343.161 152.772C349.527 152.772 353.016 155.787 353.016 164.961C353.016 174.104 349.527 177.152 343.161 177.152H336.085V152.772H343.161Z" fill="#1D375E"/>
    <path d="M359.244 176.981C359.244 176.981 358.771 177.152 357.857 177.152C356.908 177.152 356.468 176.981 356.468 176.981V174.511C356.468 174.511 356.908 174.341 357.857 174.341C358.771 174.341 359.244 174.511 359.244 174.511V176.981Z" fill="#1D375E"/>
    <path d="M377.327 168.552C374.519 168.552 373.128 169.567 373.128 172.073C373.128 174.511 374.484 175.527 376.617 175.527C379.596 175.527 381.425 173.223 381.425 170.176V168.823C380.645 168.755 378.952 168.552 377.327 168.552V168.552ZM377.735 159.003C382.576 159.003 383.861 161.339 383.861 165.843V176.981C383.861 176.981 383.559 177.152 382.88 177.152C382.271 177.152 381.899 176.981 381.899 176.981L381.527 174.443C380.577 176.406 378.547 177.491 375.837 177.491C372.689 177.491 370.591 175.627 370.591 172.073C370.591 168.517 372.723 166.789 376.989 166.789C378.887 166.789 380.577 166.959 381.425 167.061V165.234C381.425 162.353 380.205 161.101 377.429 161.101C374.891 161.101 372.689 161.948 372.689 161.948C372.621 161.915 372.317 161.677 372.113 161.204C371.909 160.696 371.943 160.255 371.943 160.155C371.943 160.155 374.519 159.003 377.735 159.003" fill="#1D375E"/>
    <path d="M401.805 176.981C401.805 176.981 401.365 177.152 400.585 177.152C399.74 177.152 399.367 176.981 399.367 176.981V164.555C399.367 162.218 398.147 161.034 395.709 161.034C393.847 161.034 391.985 162.185 390.971 164.115V176.981C390.971 176.981 390.561 177.152 389.751 177.152C388.905 177.152 388.531 176.981 388.531 176.981V159.511C388.531 159.511 388.905 159.341 389.751 159.341C390.561 159.341 390.971 159.511 390.971 159.511V161.644C392.289 159.984 394.22 159.003 396.589 159.003C399.908 159.003 401.805 161.034 401.805 164.081V176.981Z" fill="#1D375E"/>
    <path d="M413.14 161.068C409.856 161.068 408.364 162.896 408.364 168.247C408.364 173.596 409.856 175.424 413.14 175.424C416.016 175.424 417.543 172.885 417.543 170.38V166.045C417.508 163.573 415.983 161.068 413.14 161.068ZM418.759 151.757C419.607 151.757 419.979 151.925 419.979 151.925V176.981C419.979 176.981 419.573 177.152 418.964 177.152C418.32 177.152 417.88 176.981 417.88 176.981L417.641 174.713C416.66 176.406 415.001 177.491 412.327 177.491C408.364 177.491 405.825 175.153 405.825 168.247C405.825 161.339 408.364 159.003 412.327 159.003C414.899 159.003 416.524 159.984 417.543 161.644V151.925C417.543 151.925 417.913 151.757 418.759 151.757" fill="#1D375E"/>
    <path d="M441.72 152.434C445.411 152.434 447.409 153.551 447.409 153.551C447.444 154.364 447.104 154.973 446.629 155.447C446.629 155.447 444.701 154.6 441.992 154.6C437.757 154.6 434.881 156.904 434.881 164.996C434.881 173.088 437.757 175.255 441.992 175.255C444.971 175.255 447.001 174.41 447.001 174.41C447.477 174.883 447.747 175.627 447.781 176.339C447.781 176.339 445.683 177.491 441.72 177.491C435.423 177.491 432.24 173.325 432.24 164.961C432.24 156.7 435.423 152.434 441.72 152.434" fill="#1D375E"/>
    <path d="M457.112 168.552C454.3 168.552 452.913 169.567 452.913 172.073C452.913 174.511 454.267 175.527 456.4 175.527C459.38 175.527 461.208 173.223 461.208 170.176V168.823C460.431 168.755 458.737 168.552 457.112 168.552V168.552ZM457.517 159.003C462.359 159.003 463.647 161.339 463.647 165.843V176.981C463.647 176.981 463.34 177.152 462.665 177.152C462.056 177.152 461.681 176.981 461.681 176.981L461.309 174.443C460.363 176.406 458.331 177.491 455.623 177.491C452.471 177.491 450.372 175.627 450.372 172.073C450.372 168.517 452.505 166.789 456.773 166.789C458.669 166.789 460.363 166.959 461.208 167.061V165.234C461.208 162.353 459.991 161.101 457.213 161.101C454.672 161.101 452.471 161.948 452.471 161.948C452.405 161.915 452.1 161.677 451.899 161.204C451.695 160.696 451.728 160.255 451.728 160.155C451.728 160.155 454.3 159.003 457.517 159.003" fill="#1D375E"/>
    <path d="M471.724 170.989C471.724 174.24 472.639 175.459 475.315 175.459C476.16 175.459 476.973 175.289 476.973 175.289C476.973 175.289 477.175 175.695 477.175 176.305C477.175 176.88 477.039 177.22 477.039 177.22C476.701 177.32 475.788 177.491 474.671 177.491C470.673 177.491 469.287 175.222 469.287 171.091V161.271H466.645C466.645 161.271 466.476 160.934 466.476 160.323C466.476 159.645 466.645 159.341 466.645 159.341H469.287V156.124C469.931 155.684 470.844 155.515 471.724 155.581V159.341H476.565C476.565 159.341 476.735 159.679 476.735 160.289C476.735 160.899 476.565 161.271 476.565 161.271H471.724V170.989Z" fill="#1D375E"/>
    <path d="M493.624 176.981C493.624 176.981 493.184 177.152 492.404 177.152C491.559 177.152 491.184 176.981 491.184 176.981V164.555C491.184 162.218 489.965 161.034 487.528 161.034C485.665 161.034 483.804 162.185 482.789 164.115V176.981C482.789 176.981 482.38 177.152 481.569 177.152C480.724 177.152 480.349 176.981 480.349 176.981V151.925C480.349 151.925 480.724 151.757 481.569 151.757C482.38 151.757 482.789 151.925 482.789 151.925V161.644C484.108 159.984 486.04 159.003 488.408 159.003C491.727 159.003 493.624 161.034 493.624 164.081V176.981" fill="#1D375E"/>
    <path d="M504.812 161C502.104 161 500.275 162.795 500.073 167.197H508.672C508.707 166.959 508.772 166.316 508.772 165.267C508.772 162.964 507.723 161 504.812 161ZM500.073 169.025C500.308 173.664 502.036 175.324 505.32 175.324C507.893 175.324 509.449 174.205 509.449 174.205C509.924 174.577 510.299 175.289 510.332 176C510.332 176 508.468 177.491 504.847 177.491C499.971 177.491 497.531 174.272 497.531 168.144C497.531 161.745 500.479 159.003 504.812 159.003C508.908 159.003 511.177 161.44 511.177 165.537C511.177 167.636 510.805 169.025 510.805 169.025H500.073Z" fill="#1D375E"/>
    <path d="M522.041 159.037C523.499 159.037 524.48 159.544 524.955 159.849C524.852 160.728 524.515 161.372 523.837 161.915C523.091 161.44 522.449 161.237 521.533 161.237C519.976 161.237 518.487 162.592 517.472 164.623V176.981C517.472 176.981 517.097 177.152 516.252 177.152C515.407 177.152 515.032 176.981 515.032 176.981V159.511C515.032 159.511 515.407 159.341 516.252 159.341C517.097 159.341 517.472 159.511 517.472 159.511V162.287C518.893 159.679 520.655 159.037 522.041 159.037" fill="#1D375E"/>
    <path d="M530.516 176.981C530.516 176.981 530.111 177.152 529.296 177.152C528.417 177.152 528.079 176.981 528.079 176.981V159.511C528.079 159.511 528.417 159.341 529.296 159.341C530.111 159.341 530.516 159.511 530.516 159.511V176.981ZM530.685 155.481C530.685 155.481 530.212 155.651 529.296 155.651C528.349 155.651 527.909 155.481 527.909 155.481V153.01C527.909 153.01 528.349 152.84 529.296 152.84C530.212 152.84 530.685 153.01 530.685 153.01V155.481Z" fill="#1D375E"/>
    <path d="M548.545 176.981C548.545 176.981 548.105 177.152 547.327 177.152C546.48 177.152 546.109 176.981 546.109 176.981V164.555C546.109 162.218 544.889 161.034 542.451 161.034C540.589 161.034 538.725 162.185 537.711 164.115V176.981C537.711 176.981 537.304 177.152 536.491 177.152C535.645 177.152 535.273 176.981 535.273 176.981V159.511C535.273 159.511 535.645 159.341 536.491 159.341C537.304 159.341 537.711 159.511 537.711 159.511V161.644C539.032 159.984 540.961 159.003 543.332 159.003C546.651 159.003 548.545 161.034 548.545 164.081V176.981" fill="#1D375E"/>
    <path d="M559.736 161C557.028 161 555.199 162.795 554.997 167.197H563.597C563.631 166.959 563.696 166.316 563.696 165.267C563.696 162.964 562.647 161 559.736 161ZM554.997 169.025C555.232 173.664 556.96 175.324 560.244 175.324C562.817 175.324 564.375 174.205 564.375 174.205C564.848 174.577 565.223 175.289 565.256 176C565.256 176 563.393 177.491 559.771 177.491C554.895 177.491 552.456 174.272 552.456 168.144C552.456 161.745 555.403 159.003 559.736 159.003C563.832 159.003 566.101 161.44 566.101 165.537C566.101 167.636 565.729 169.025 565.729 169.025H554.997Z" fill="#1D375E"/>
    <path d="M592.313 152.772C592.313 152.772 592.515 153.177 592.515 153.856C592.515 154.499 592.313 154.973 592.313 154.973H585.709V176.948C585.709 176.948 585.269 177.152 584.456 177.152C583.576 177.152 583.205 176.948 583.205 176.948V154.973H576.432C576.432 154.973 576.228 154.532 576.228 153.856C576.228 153.212 576.432 152.772 576.432 152.772H592.313Z" fill="#1D375E"/>
    <path d="M595.665 176.981C595.665 176.981 595.192 177.152 594.279 177.152C593.328 177.152 592.888 176.981 592.888 176.981V174.511C592.888 174.511 593.328 174.341 594.279 174.341C595.192 174.341 595.665 174.511 595.665 174.511V176.981Z" fill="#1D375E"/>
    <path d="M23.4546 195.469C24.4718 195.469 25.114 195.672 25.114 195.672V219.646C25.114 219.646 24.6745 219.848 23.862 219.848C23.0489 219.848 22.6093 219.646 22.6093 219.646V205.289C22.6093 202.376 22.6421 199.701 22.7786 198.517H22.676C22.4052 199.396 21.4578 201.428 20.3745 203.493L14.7197 213.516C14.7197 213.516 14.3812 213.652 13.8734 213.652C13.3994 213.652 13.0948 213.516 13.0948 213.516C11.2995 210.197 9.47134 206.915 7.67706 203.561C6.59373 201.563 5.57706 199.396 5.34009 198.517H5.23957C5.44217 199.499 5.50988 202.139 5.50988 205.118V219.646C5.50988 219.646 5.13695 219.848 4.35884 219.848C3.61405 219.848 3.20728 219.646 3.20728 219.646V195.672C3.20728 195.672 3.7828 195.469 4.73176 195.469C5.54269 195.469 6.25467 195.672 6.25467 195.672L12.1458 206.439C12.9927 208.064 13.6693 209.522 13.9073 210.265H14.0421C14.1442 209.86 14.5156 208.979 15.1937 207.76C17.4286 203.73 19.6286 199.701 21.8297 195.672C22.2026 195.569 22.7786 195.469 23.4546 195.469" fill="#1D375E"/>
    <path d="M35.9088 211.248C33.0989 211.248 31.7109 212.264 31.7109 214.769C31.7109 217.208 33.065 218.223 35.1984 218.223C38.1781 218.223 40.0062 215.922 40.0062 212.873V211.518C39.227 211.451 37.5338 211.248 35.9088 211.248V211.248ZM36.3156 201.7C41.1578 201.7 42.4437 204.035 42.4437 208.539V219.68C42.4437 219.68 42.139 219.848 41.4625 219.848C40.8526 219.848 40.4797 219.68 40.4797 219.68L40.1078 217.14C39.1593 219.104 37.1286 220.187 34.4197 220.187C31.2697 220.187 29.1708 218.325 29.1708 214.769C29.1708 211.215 31.3036 209.487 35.5713 209.487C37.4672 209.487 39.1593 209.656 40.0062 209.758V207.929C40.0062 205.051 38.7874 203.799 36.0109 203.799C33.4708 203.799 31.2697 204.645 31.2697 204.645C31.203 204.611 30.8978 204.375 30.6953 203.9C30.4912 203.392 30.525 202.952 30.525 202.851C30.525 202.851 33.0989 201.7 36.3156 201.7" fill="#1D375E"/>
    <path d="M48.8937 210.943C48.8937 215.785 50.5531 218.02 54.2093 218.02C56.1735 218.02 57.4937 217.376 57.4937 217.376C57.9677 217.749 58.2385 218.46 58.2729 219.172C58.2729 219.172 56.7156 220.187 53.6339 220.187C48.8937 220.187 46.3541 216.971 46.3541 210.943C46.3541 204.916 48.8937 201.7 53.6339 201.7C56.5792 201.7 57.8995 202.512 57.8995 202.512C57.8995 203.223 57.6631 203.9 57.1552 204.34C57.1552 204.34 55.9708 203.764 54.0735 203.764C50.4511 203.764 48.8937 206.135 48.8937 210.943" fill="#1D375E"/>
    <path d="M70.988 198.381C70.988 198.381 70.7855 199.396 70.3104 200.717L66.3833 211.079H75.6953L71.7667 200.717C71.2932 199.363 71.0901 198.381 71.0901 198.381H70.988ZM71.1245 195.469C72.0036 195.469 72.5115 195.672 72.5115 195.672L81.4844 219.646C81.4844 219.646 81.0448 219.848 80.0959 219.848C79.2833 219.848 78.8776 219.646 78.8776 219.646L76.4401 213.179H65.6385L63.1667 219.646C63.1667 219.646 62.7599 219.848 61.9473 219.848C61.1344 219.848 60.7631 219.646 60.7631 219.646L69.736 195.672C69.736 195.672 70.2776 195.469 71.1245 195.469" fill="#1D375E"/>
    <path d="M91.7136 201.734C93.1703 201.734 94.1516 202.241 94.6255 202.545C94.5245 203.425 94.186 204.069 93.5088 204.611C92.7631 204.137 92.1208 203.935 91.2063 203.935C89.6489 203.935 88.1584 205.289 87.1427 207.32V219.68C87.1427 219.68 86.7697 219.848 85.9245 219.848C85.0781 219.848 84.7047 219.68 84.7047 219.68V202.206C84.7047 202.206 85.0781 202.039 85.9245 202.039C86.7697 202.039 87.1427 202.206 87.1427 202.206V204.984C88.5651 202.376 90.3255 201.734 91.7136 201.734" fill="#1D375E"/>
    <path d="M102.09 213.687C102.09 216.936 103.004 218.155 105.679 218.155C106.525 218.155 107.339 217.987 107.339 217.987C107.339 217.987 107.541 218.392 107.541 219.001C107.541 219.577 107.406 219.916 107.406 219.916C107.068 220.017 106.153 220.187 105.035 220.187C101.04 220.187 99.6516 217.918 99.6516 213.787V203.967H97.0109C97.0109 203.967 96.8407 203.629 96.8407 203.02C96.8407 202.341 97.0109 202.039 97.0109 202.039H99.6516V198.82C100.295 198.381 101.209 198.212 102.09 198.279V202.039H106.931C106.931 202.039 107.102 202.376 107.102 202.985C107.102 203.596 106.931 203.967 106.931 203.967H102.09V213.687Z" fill="#1D375E"/>
    <path d="M123.987 219.68C123.987 219.68 123.548 219.848 122.769 219.848C121.923 219.848 121.549 219.68 121.549 219.68V207.252C121.549 204.916 120.33 203.73 117.893 203.73C116.03 203.73 114.168 204.881 113.153 206.812V219.68C113.153 219.68 112.745 219.848 111.934 219.848C111.087 219.848 110.715 219.68 110.715 219.68V194.623C110.715 194.623 111.087 194.453 111.934 194.453C112.745 194.453 113.153 194.623 113.153 194.623V204.34C114.473 202.681 116.403 201.7 118.773 201.7C122.092 201.7 123.987 203.73 123.987 206.777V219.68" fill="#1D375E"/>
    <path d="M128.556 202.206C128.556 202.206 128.996 202.039 129.774 202.039C130.621 202.039 130.993 202.206 130.993 202.206V214.634C130.993 216.971 132.212 218.155 134.684 218.155C136.479 218.155 138.34 217.037 139.391 215.075V202.206C139.391 202.206 139.797 202.039 140.609 202.039C141.456 202.039 141.828 202.206 141.828 202.206V219.68C141.828 219.68 141.456 219.848 140.609 219.848C139.797 219.848 139.391 219.68 139.391 219.68V217.547C138.069 219.204 136.107 220.187 133.769 220.187C130.451 220.187 128.556 218.155 128.556 215.106V202.206Z" fill="#1D375E"/>
    <path d="M153.579 201.734C155.035 201.734 156.016 202.241 156.491 202.545C156.389 203.425 156.051 204.069 155.375 204.611C154.628 204.137 153.985 203.935 153.071 203.935C151.513 203.935 150.024 205.289 149.008 207.32V219.68C149.008 219.68 148.635 219.848 147.789 219.848C146.943 219.848 146.569 219.68 146.569 219.68V202.206C146.569 202.206 146.943 202.039 147.789 202.039C148.635 202.039 149.008 202.206 149.008 202.206V204.984C150.431 202.376 152.191 201.734 153.579 201.734Z" fill="#1D375E"/>
    <path d="M170.207 219.646C170.207 219.646 169.8 219.848 168.953 219.848C168.107 219.848 167.701 219.646 167.701 219.646V195.469H181.008C181.076 195.705 181.211 196.045 181.211 196.518C181.211 196.992 181.109 197.332 181.008 197.568H170.207V206.812H179.653C179.756 207.015 179.857 207.388 179.857 207.828C179.857 208.301 179.756 208.675 179.653 208.911H170.207V219.646" fill="#1D375E"/>
    <path d="M190.833 203.629C187.921 203.629 186.093 205.289 186.093 210.943C186.093 216.597 187.921 218.258 190.833 218.258C193.745 218.258 195.575 216.597 195.575 210.943C195.575 205.289 193.745 203.629 190.833 203.629ZM190.833 201.7C195.369 201.7 198.079 204.237 198.079 210.943C198.079 217.647 195.369 220.187 190.833 220.187C186.296 220.187 183.588 217.647 183.588 210.943C183.588 204.237 186.296 201.7 190.833 201.7" fill="#1D375E"/>
    <path d="M202.036 202.206C202.036 202.206 202.476 202.039 203.255 202.039C204.101 202.039 204.472 202.206 204.472 202.206V214.634C204.472 216.971 205.692 218.155 208.164 218.155C209.959 218.155 211.82 217.037 212.871 215.075V202.206C212.871 202.206 213.277 202.039 214.089 202.039C214.936 202.039 215.308 202.206 215.308 202.206V219.68C215.308 219.68 214.936 219.848 214.089 219.848C213.277 219.848 212.871 219.68 212.871 219.68V217.547C211.549 219.204 209.587 220.187 207.249 220.187C203.931 220.187 202.036 218.155 202.036 215.106V202.206Z" fill="#1D375E"/>
    <path d="M233.324 219.68C233.324 219.68 232.885 219.848 232.105 219.848C231.26 219.848 230.887 219.68 230.887 219.68V207.252C230.887 204.916 229.668 203.73 227.229 203.73C225.367 203.73 223.505 204.881 222.489 206.812V219.68C222.489 219.68 222.084 219.848 221.269 219.848C220.424 219.848 220.051 219.68 220.051 219.68V202.206C220.051 202.206 220.424 202.039 221.269 202.039C222.084 202.039 222.489 202.206 222.489 202.206V204.34C223.809 202.681 225.74 201.7 228.109 201.7C231.428 201.7 233.324 203.73 233.324 206.777V219.68" fill="#1D375E"/>
    <path d="M244.66 203.764C241.375 203.764 239.885 205.592 239.885 210.943C239.885 216.293 241.375 218.122 244.66 218.122C247.537 218.122 249.061 215.583 249.061 213.076V208.743C249.028 206.271 247.503 203.764 244.66 203.764ZM250.28 194.453C251.127 194.453 251.5 194.623 251.5 194.623V219.68C251.5 219.68 251.092 219.848 250.483 219.848C249.84 219.848 249.399 219.68 249.399 219.68L249.163 217.411C248.181 219.104 246.521 220.187 243.847 220.187C239.885 220.187 237.345 217.849 237.345 210.943C237.345 204.035 239.885 201.7 243.847 201.7C246.42 201.7 248.045 202.681 249.061 204.34V194.623C249.061 194.623 249.433 194.453 250.28 194.453" fill="#1D375E"/>
    <path d="M261.795 211.248C258.984 211.248 257.596 212.264 257.596 214.769C257.596 217.208 258.951 218.223 261.084 218.223C264.064 218.223 265.892 215.922 265.892 212.873V211.518C265.113 211.451 263.42 211.248 261.795 211.248ZM262.201 201.7C267.043 201.7 268.329 204.035 268.329 208.539V219.68C268.329 219.68 268.024 219.848 267.348 219.848C266.737 219.848 266.367 219.68 266.367 219.68L265.993 217.14C265.045 219.104 263.013 220.187 260.305 220.187C257.156 220.187 255.056 218.325 255.056 214.769C255.056 211.215 257.189 209.487 261.456 209.487C263.352 209.487 265.045 209.656 265.892 209.758V207.929C265.892 205.051 264.673 203.799 261.896 203.799C259.356 203.799 257.156 204.645 257.156 204.645C257.088 204.611 256.784 204.375 256.581 203.9C256.377 203.392 256.411 202.952 256.411 202.851C256.411 202.851 258.984 201.7 262.201 201.7" fill="#1D375E"/>
    <path d="M276.407 213.687C276.407 216.936 277.323 218.155 279.996 218.155C280.844 218.155 281.656 217.987 281.656 217.987C281.656 217.987 281.859 218.392 281.859 219.001C281.859 219.577 281.724 219.916 281.724 219.916C281.385 220.017 280.471 220.187 279.353 220.187C275.357 220.187 273.969 217.918 273.969 213.787V203.967H271.329C271.329 203.967 271.159 203.629 271.159 203.02C271.159 202.341 271.329 202.039 271.329 202.039H273.969V198.82C274.613 198.381 275.527 198.212 276.407 198.279V202.039H281.249C281.249 202.039 281.419 202.376 281.419 202.985C281.419 203.596 281.249 203.967 281.249 203.967H276.407V213.687Z" fill="#1D375E"/>
    <path d="M287.521 219.68C287.521 219.68 287.115 219.848 286.301 219.848C285.421 219.848 285.083 219.68 285.083 219.68V202.206C285.083 202.206 285.421 202.039 286.301 202.039C287.115 202.039 287.521 202.206 287.521 202.206V219.68ZM287.691 198.177C287.691 198.177 287.216 198.347 286.301 198.347C285.355 198.347 284.913 198.177 284.913 198.177V195.705C284.913 195.705 285.355 195.537 286.301 195.537C287.216 195.537 287.691 195.705 287.691 195.705V198.177Z" fill="#1D375E"/>
    <path d="M298.791 203.629C295.879 203.629 294.051 205.289 294.051 210.943C294.051 216.597 295.879 218.258 298.791 218.258C301.703 218.258 303.532 216.597 303.532 210.943C303.532 205.289 301.703 203.629 298.791 203.629ZM298.791 201.7C303.328 201.7 306.037 204.237 306.037 210.943C306.037 217.647 303.328 220.187 298.791 220.187C294.253 220.187 291.545 217.647 291.545 210.943C291.545 204.237 294.253 201.7 298.791 201.7" fill="#1D375E"/>
    <path d="M323.381 219.68C323.381 219.68 322.941 219.848 322.161 219.848C321.316 219.848 320.944 219.68 320.944 219.68V207.252C320.944 204.916 319.725 203.73 317.285 203.73C315.425 203.73 313.561 204.881 312.547 206.812V219.68C312.547 219.68 312.14 219.848 311.327 219.848C310.481 219.848 310.109 219.68 310.109 219.68V202.206C310.109 202.206 310.481 202.039 311.327 202.039C312.14 202.039 312.547 202.206 312.547 202.206V204.34C313.868 202.681 315.796 201.7 318.168 201.7C321.487 201.7 323.381 203.73 323.381 206.777V219.68" fill="#1D375E"/>
    </svg>
      )
}

export default SJCLogo
